<template>
  <b-container class="mt-7 mb-7">
    <b-alert v-model="success.model" variant="success" dismissible fade>
      {{ success.text }}
    </b-alert>
    <b-alert v-model="alert.model" variant="danger" dismissible fade>
      {{ alert.text }}
    </b-alert>
    <b-tabs pills vertical v-if="organization">
      <b-tab title="Stripe Connect" class="ml-4" v-if="canEditStripe">
        <h1>
          <span class="text-muted">Account ></span>
          Stripe Connect
        </h1>
        <ViewStripeConnectDetails :organization="organization" />
      </b-tab>
      <b-tab title="Billing" class="ml-4" v-if="canEditBilling" lazy>
        <h1>
          <span class="text-muted">Account ></span>
          Billing
        </h1>
        <EditBilling :organization="organization" />
      </b-tab>
      <b-tab title="Stripe Deposits" class="ml-4" lazy v-if="showAccountPayouts">
        <h1>
          <span class="text-muted">Account ></span>
          Stripe Deposits
        </h1>
        <ViewPayout :organization="organization" />
      </b-tab>
      <b-tab title="Invoices" v-if="showAccountInvoices" class="ml-4" lazy>
        <h1>
          <span class="text-muted">Account ></span>
          Invoices
        </h1>
        <Invoices />
      </b-tab>
      <b-tab title="Invoices (New)" v-if="showQuickbooksInvoices" class="ml-4" lazy>
        <h1>
          <span class="text-muted">Account ></span>
          Invoices
        </h1>
        <InvoicesQBO />
      </b-tab>
    </b-tabs>
  </b-container>
</template>

<script>
import { getAuth } from '@rafflebox-technologies-inc/auth-service-sdk';

import OrganizationServiceV2 from '@/lib/organization-service-v2';
import ViewPayout from '@/components/ViewPayout.vue';
import Invoices from '@/components/Invoices.vue';
import InvoicesQBO from '@/components/InvoicesQBO.vue';
import ViewStripeConnectDetails from '@/components/ViewStripeConnectDetails.vue';
import EditBilling from '@/components/forms/EditBilling.vue';
import { unleashFeatureEnabled, UnleashKeys } from '@/lib/unleash';

export default {
  name: 'Organization',
  components: {
    ViewPayout,
    Invoices,
    ViewStripeConnectDetails,
    EditBilling,
    InvoicesQBO
  },
  data() {
    return {
      organization: null,
      organizationLoading: true,
      merchantLoading: true,
      stripeAccount: null,
      alert: {
        text: '',
        model: false
      },
      success: {
        text: '',
        model: false
      },
      showQuickbooksInvoices: false,
      canEditBilling: false,
      canEditStripe: false,
      showAccountPayouts: false,
      showAccountInvoices: false
    };
  },

  async mounted() {
    this.loadOrganization();
    this.showQuickbooksInvoices = await unleashFeatureEnabled(UnleashKeys.QuickbooksInvoices);
    this.canEditBilling = await unleashFeatureEnabled(UnleashKeys.EditBilling);
    this.canEditStripe = await unleashFeatureEnabled(UnleashKeys.AccountStripe);
    this.showAccountPayouts = await unleashFeatureEnabled(UnleashKeys.AccountPayouts);
    this.showAccountInvoices = await unleashFeatureEnabled(UnleashKeys.AccountInvoices);
  },

  methods: {
    async editInStripe() {
      this.editingInStripe = true;

      try {
        const response = await OrganizationServiceV2.createAccountLink(
          this.organization.stripeConnectedAccountId,
          this.organization.id,
          this.organization.country
        );
        window.location.href = response.accountLink;
      } catch (error) {
        this.editingInStripe = false;
        this.handleError(error);
      }
    },

    async createStripeAccount() {
      this.creatingStripeAccount = true;

      try {
        const response = await OrganizationServiceV2.createAccount('stripe');
        this.organization.stripeConnectedAccountId = response.accountId;
        this.editInStripe();
      } catch (error) {
        this.handleError(error);
        this.creatingStripeAccount = false;
      }
    },

    async loadAccounts() {
      if (this.organization) {
        if (this.organization.stripeConnectedAccountId) {
          const account = await OrganizationServiceV2.retrieveAccount(this.organization.stripeConnectedAccountId);

          this.stripeAccount = {};
          this.stripeAccount.id = account.id;
          this.stripeAccount.name = account.name;
          this.stripeAccount.city = account.city;
          this.stripeAccount.address = account.address;
          this.stripeAccount.postal = this.formatPostal(account.postal);
          this.stripeAccount.province = account.province;
          this.stripeAccount.currency = account.currency;
          this.stripeAccount.email = account.email;
          this.stripeAccount.active = account.active;
          this.stripeAccount.provider = account.provider;
          this.stripeAccount.firstName = account.firstName;
          this.stripeAccount.lastName = account.lastName;
          this.stripeAccount.phone = this.formatPhone(account.phone);
          this.stripeAccount.availableBalance = this.formatCurrency(account.availableBalanceCents / 100);
          this.stripeAccount.pendingBalance = this.formatCurrency(account.pendingBalanceCents / 100);
          this.stripeAccount.providerData = account.providerData;
        }
      }
    },

    handleSuccess(text) {
      this.success.text = text;
      this.success.model = true;
    },

    handleError(error) {
      this.alert.text = error.response ? error.response.data.errors[0].message : error;
      this.alert.model = true;
    },

    isDefaultMerchant(merchantType) {
      return this.organization?.defaultMerchant === merchantType;
    },

    async loadOrganization() {
      this.organizationLoading = true;

      try {
        const sessionUser = await getAuth().sessionUser();
        const organization = await OrganizationServiceV2.retrieveOrganization(sessionUser.organizationUuid);

        this.organization = organization;

        this.loadAccounts();

        this.merchantLoading = false;
      } catch (error) {
        this.handleError(error);
      }
      this.organizationLoading = false;
    }
  }
};
</script>

<style scoped>
.container {
  text-align: left;
  margin-left: 115px;
  max-width: 90%;
}

.organization-name {
  font-size: 2.5rem;
  line-height: 1.2;
}

.organization-name + button {
  margin-left: 1em;
}

.title-max-width {
  max-width: 50%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.title-max-width + button {
  margin-top: 5px;
  margin-bottom: auto;
}

.title-max-width:hover {
  max-width: 70%;
  white-space: inherit;
}

a {
  text-decoration: none;
  color: black;
}
</style>
