<template>
  <div class="mb-5">
    <b-card>
      <b-card-body>
        <dl class="row mt-2">
          <dt class="col-3">
            Deposit Frequency
            <span
              v-b-tooltip.hover.right="
                'The frequency at which the funds from your Stripe account are transfered to your financial instritution. Please contact support if you want to change this.'
              "
            >
              <i class="fa-solid fa-circle-info"></i>
            </span>
          </dt>
          <dd class="pl-2 text-left">
            <span class="capitalize">{{ depositFrequency }}</span>

            <span v-if="depositFrequency === 'weekly'">
              on <span class="capitalize">{{ depositDay }}</span>
            </span>

            <span v-else-if="depositFrequency === 'monthly'">
              on the <span class="capitalize">{{ getDayWithSuffix(depositDay) }}</span>
            </span>
          </dd>
        </dl>
        <dl class="row mt-2">
          <dt class="col-3">
            Available Balance
            <span
              v-b-tooltip.hover.right="
                'The amount of funds in Stripe available to be transfered to your financial institution.'
              "
            >
              <i class="fa-solid fa-circle-info"></i>
            </span>
          </dt>
          <dd class="col-9 pl-2 text-left">{{ formatCurrency(stripeAccount.availableBalanceCents / 100) }}</dd>
        </dl>

        <dl class="row mt-2">
          <dt class="col-3">
            Pending Balance
            <span
              v-b-tooltip.hover.right="
                'The amount of funds in your Stripe account that will be available. Typically this takes 3-5 business days.'
              "
            >
              <i class="fa-solid fa-circle-info"></i>
            </span>
          </dt>
          <dd class="col-2 pl-2">{{ formatCurrency(stripeAccount.pendingBalanceCents / 100) }}</dd>
        </dl>

        <b-form-group v-if="depositFrequency === 'manual'" class="mt-4">
          <b-button variant="success" v-b-modal.payout-modal v>Transfer Funds from Stripe to my Bank</b-button>
        </b-form-group>
      </b-card-body>
    </b-card>

    <!-- Confirm Deposit -->
    <b-modal
      id="payout-modal"
      centered
      title="Transfer Funds"
      @ok="depositFunds($event)"
      body-class="position-static"
      no-close-on-backdrop
    >
      <b-overlay :show="confirmingPayout" no-wrap rounded="sm" />
      <Alert variant="blue" icon="info">
        Transfering funds from Stripe to your financial institution cannot be reversed.
      </Alert>
      <div v-if="!allowPayout()">
        <Alert variant="yellow" icon="exclamation" show>
          <strong>Deposits have been disabled.</strong> There are no funds in your available balance. Stripe will take 5
          business days to clear your pending transactions for deposit.
        </Alert>
      </div>
      <div v-else>
        <div class="mt-2">
          <!-- Manual Payout Section -->
          <b-form @submit.stop.prevent="onSubmit">
            <b-form-group
              label-for="input-payout-amount"
              :invalid-feedback="veeErrors.first('input-payout-amount')"
              style="width: 15rem"
              v-if="allowPayout()"
              label="Deposit Amount"
            >
              <b-input-group prepend="$" class="mr-3">
                <b-form-input
                  name="input-payout-amount"
                  v-model="payoutAmount"
                  type="number"
                  :state="validateState('input-payout-amount')"
                  step="any"
                  v-validate="{ min_value: 0.1, max_value: availableBalance(), required: true }"
                  aria-describedby="input-payout-amount-feedback"
                  data-vv-as="payout amount"
                />
              </b-input-group>
            </b-form-group>
            <b-form-group
              class="mt-2"
              v-if="bankAccountOptions.length > 0"
              label="Bank Account"
              label-for="input-bank-id"
              :invalid-feedback="veeErrors.first('input-bank-id')"
            >
              <b-form-select
                class="col-12"
                name="input-bank-id"
                :state="validateState('input-bank-id')"
                v-validate="'required'"
                v-model="bankAccountId"
                aria-describedby="input-frequency-feedback"
                :options="bankAccountOptions"
                data-vv-as="frequency"
              />
            </b-form-group>
          </b-form>
        </div>
        <Alert v-if="payoutFailed" show variant="red" icon="exclamation">{{ errorMessage }}</Alert>
      </div>
    </b-modal>
  </div>
</template>

<script>
import OrganizationServiceV2 from '@/lib/organization-service-v2';
import { format } from 'date-fns';
import Alert from '@/components/ui/Alert';

export default {
  props: ['stripeAccount'],
  components: {
    Alert
  },
  data() {
    return {
      payoutAmount: 0,
      payoutFailed: false,
      errorMessage: null,
      payoutSucceeded: false,
      successMessage: null,
      confirmingPayout: false,
      bankAccountId: null,
      bankAccountOptions: []
    };
  },
  created() {
    this.resetForm();
    this.setupBankAccountOptions();
  },
  computed: {
    depositFrequency() {
      return this.stripeAccount.providerData.payoutSettings.interval;
    },
    depositDay() {
      return (
        this.stripeAccount.providerData.payoutSettings.weeklyAnchor ||
        this.stripeAccount.providerData.payoutSettings.monthlyAnchor
      );
    }
  },
  methods: {
    getDayWithSuffix(dayOfTheMonth) {
      return format(new Date(0, 0, dayOfTheMonth), 'do');
    },

    validateState(ref) {
      if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },

    setupBankAccountOptions() {
      const options = [];

      if (!this.stripeAccount || !this.stripeAccount.bankAccounts) {
        return options;
      }

      for (const bankAccount of this.stripeAccount.bankAccounts) {
        options.push({
          value: bankAccount.id,
          text: `${bankAccount.bankName} ****${bankAccount.last4}`
        });
      }

      this.bankAccountOptions = options;
      this.bankAccountId = this.stripeAccount.bankAccount.id;
    },
    resetForm() {
      this.payoutAmount =
        this.stripeAccount && this.stripeAccount.availableBalanceCents > 0
          ? this.stripeAccount.availableBalanceCents / 100
          : null;

      this.payoutAmount = 0;
      this.payoutFailed = false;
      this.payoutSucceeded = false;
      this.successMessage = null;
      this.errorMessage = null;
      this.confirmingPayout = false;
      this.bankAccountId = this.stripeAccount.bankAccount.id;

      this.$bvModal.hide('payout-modal');

      this.$nextTick(() => {
        this.$validator.reset();
      });
    },
    availableBalance() {
      return this.stripeAccount.availableBalanceCents / 100;
    },
    allowPayout() {
      return this.availableBalance() > 0 && this.depositFrequency === 'manual';
    },
    async depositFunds(bvtModalEvt) {
      bvtModalEvt.preventDefault();

      this.confirmingPayout = true;

      const valid = await this.$validator.validateAll();

      if (!valid) {
        this.$root.$emit('bv::hide::modal', 'confirm-payout-modal');
        this.confirmingPayout = false;

        return;
      }

      try {
        const body = {
          provider: this.stripeAccount.provider,
          amountCents: Math.round(this.payoutAmount * 100),
          bankAccountId: this.bankAccountId
        };

        await OrganizationServiceV2.createPayout(this.stripeAccount.id, body);

        this.payoutSucceeded = true;
        this.successMessage = 'Payout successful!';
        this.confirmingPayout = false;
        this.$root.$emit('bv::hide::modal', 'confirm-payout-modal');
        this.$emit('payoutCreated');
        this.resetForm(true);
      } catch (error) {
        this.payoutFailed = true;

        if (error.response) {
          this.errorMessage = error.response.data.errors[0].message;
        } else {
          this.errorMessage = 'An unexpected error occurred, please contact Support.';
        }
      } finally {
        this.confirmingPayout = false;
      }
    }
  }
};
</script>

<style scoped>
.capitalize::first-letter {
  text-transform: capitalize;
}
</style>
