<template>
  <div v-if="stripeAccount">
    <b-row>
      <b-col cols="12">
        <ManualPayout :stripeAccount="stripeAccount" @payoutCreated="updateTable()" />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <h4 class="mb-4">Deposit History</h4>
        <p>List of all the transfers from your Stripe account to your financial institution.</p>
        <PayoutTable
          class="mt-2"
          :organization="organization"
          :stripeAccount="stripeAccount"
          :newPayout="newPayout"
          @refreshed="tableRefreshed()"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import OrganizationServiceV2 from '@/lib/organization-service-v2';
import PayoutTable from '@/components/PayoutTable.vue';
import ManualPayout from '@/components/ManualPayout.vue';

export default {
  components: { PayoutTable, ManualPayout },
  props: {
    organization: {
      type: Object
    }
  },
  data() {
    return {
      stripeAccount: null,
      paymentFrequency: null,
      monthlyAnchorOptions: this.createMonthlyAnchorOptions,
      editPayoutFailed: false,
      errorMessage: null,
      editPayoutSucceeded: false,
      successMessage: null,
      newPayout: false
    };
  },
  created() {
    this.retrieveAccount();
    this.monthlyAnchorOptions = this.createMonthlyAnchorOptions();
  },
  methods: {
    validateState(ref) {
      if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    async retrieveAccount() {
      if (this.organization) {
        if (this.organization.stripeConnectedAccountId) {
          this.stripeAccount = await OrganizationServiceV2.retrieveAccount(this.organization.stripeConnectedAccountId);
        }
      }
    },
    createMonthlyAnchorOptions() {
      let num = 1;
      const monthlyAnchorOptions = [];

      while (num <= 31) {
        monthlyAnchorOptions.push({ value: num, text: `${num}` });
        num++;
      }

      return monthlyAnchorOptions;
    },
    resetForm() {
      this.$nextTick(() => {
        this.$validator.reset();
      });
    },
    async onSubmit() {
      const valid = await this.$validator.validateAll();
      if (!valid) {
        return;
      }

      try {
        const payoutSettings = {
          interval: this.stripeAccount.providerData.payoutSettings.interval
        };

        if (payoutSettings.interval === 'weekly') {
          payoutSettings.weeklyAnchor = this.stripeAccount.providerData.payoutSettings.weeklyAnchor;
        } else if (payoutSettings.interval === 'monthly') {
          payoutSettings.monthlyAnchor = this.stripeAccount.providerData.payoutSettings.monthlyAnchor;
        }

        const data = {
          provider: this.stripeAccount.provider,
          payoutSettings: payoutSettings
        };

        await OrganizationServiceV2.updateAccount(this.stripeAccount.id, data);

        this.editPayoutSucceeded = true;
        this.successMessage = 'Payout schedule updated successfully!';
        this.resetForm();
      } catch (error) {
        this.editPayoutFailed = error;
        if (error.response) {
          this.errorMessage = error.response.data.errors[0].message;
        } else {
          this.errorMessage = 'An unexpected error occurred, please try again later.';
        }
      }
    },
    updateTable() {
      this.newPayout = true;
    },
    tableRefreshed() {
      this.newPayout = false;
    }
  }
};
</script>

<style></style>
